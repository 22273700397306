import React from "react";
import PropTypes from "prop-types";

import Badge from "../components/Badge";

class Quest extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(["bronze", "silver", "gold", "platinum", "diamond"]).isRequired,
    thumbnail: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    artist: PropTypes.string.isRequired,
    mapper: PropTypes.string.isRequired,
    goal: PropTypes.string.isRequired
  };

  render() {
    const points = {
      bronze: 20,
      silver: 40,
      gold: 60,
      platinum: 80,
      diamond: 100
    };

    return (
      <div className="col-2">
        <button className="wrapper mb-4 w-100">
          <div className="card quest">
            <div className="thumbnail" style={{ backgroundImage: "url(" + this.props.thumbnail + ")" }}>
              <div className="goal">
                <h3>{this.props.goal}</h3>
              </div>
              <div className="info-panel text-start text-light px-2 py-1">
                <p className="m-0 fw-bolder text-truncate">
                  <small>{this.props.title}</small>
                </p>
                <p className="m-0 nt text-truncate">
                  <small>
                    {this.props.artist} &middot; {this.props.mapper}
                  </small>
                </p>
              </div>
            </div>
            <Badge type={this.props.type} text={points[this.props.type] + " PTS"} size="small" />
          </div>
        </button>
      </div>
    );
  }
}

export default Quest;
