import React from "react";
import PropTypes from "prop-types";
import icon from "../images/beatquest-icon-bare.svg";

class Category extends React.Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  };

  render() {
    return (
      <div className="col-2">
        <div className="category text-light mb-4">
          <div className="icon d-inline-block">
            <img src={icon} height="96" />
            <i className={"fas bottom fa-" + this.props.icon}></i>
            <i className={"fas middle fa-" + this.props.icon}></i>
            <i className={"fas top fa-" + this.props.icon}></i>
          </div>
          <h2 className="mt-3 mb-0">{this.props.name}</h2>
          <p className="m-0">{this.props.description}</p>
        </div>
      </div>
    );
  }
}

export default Category;
