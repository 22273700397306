import React from "react";
import { NavLink as Link } from "react-router-dom";

import icon from "../images/beatquest-icon.svg";

class Navbar extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-dark bg-dark navbar-expand-sm">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={icon} alt="BQ" height="32" className="d-inline-block align-text-top" />
            &nbsp; BeatQuest
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-inner">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar-inner">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  <i className="fas fa-home"></i>&nbsp; Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/faqs">
                  <i className="fas fa-question-circle"></i>&nbsp; FAQs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
