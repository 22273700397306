import React from "react";

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: 0,
      minutes: 0,
      seconds: 0
    };
    this.update = this.update.bind(this);
  }

  update() {
    const now = new Date();
    const utc = new Date(now.toLocaleString("en-US", { timeZone: "Etc/GMT-14" }));

    const target = new Date(utc);
    target.setHours(0, 0, 0, 0);

    if (utc >= target) {
      target.setDate(target.getDate() + 1);
    }

    const delta = target - utc;

    const hours = Math.floor(delta / (1000 * 60 * 60));
    const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((delta % (1000 * 60)) / 1000);

    this.setState({ hours, minutes, seconds });
  }

  componentDidMount() {
    this.update();
    setInterval(this.update, 1000);
  }

  render() {
    return (
      <>
        <span className="timer text-monospace">{this.state.hours.toString().padStart(2, "0")}</span> hours{" "}
        <span className="timer text-monospace">{this.state.minutes.toString().padStart(2, "0")}</span> minutes{" "}
        <span className="timer text-monospace">{this.state.seconds.toString().padStart(2, "0")}</span> seconds
      </>
    );
  }
}

export default Timer;
