import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";

import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import FAQs from "./pages/FAQs";

import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/faqs" element={<FAQs />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
