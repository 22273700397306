import React from "react";

import Quest from "../components/Quest";
import Category from "../components/Category";
import Badge from "../components/Badge";
import Timer from "../components/Timer";

class Pack extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  async componentDidMount() {
    const response = await fetch("/api/challenges");
    const data = await response.json();
    this.setState({ data });
    console.log(data);
  }

  render() {
    if (!this.state.data) return <></>;

    const quest = (challenge) => {
      var goal = "";
      if (challenge.type == "acc") goal = "≥ " + (challenge.cutoff * 100).toFixed(1) + "%";
      if (challenge.type == "cuts") goal = "≤ " + challenge.cutoff + " Misses";
      if (challenge.type == "cuts" && challenge.cutoff == 0) goal = "0 Misses";
      if (challenge.type == "combo") goal = "≥ " + challenge.cutoff + " Combo";

      return (
        <Quest
          key={challenge.type + challenge.level}
          thumbnail={challenge.map.i}
          title={challenge.map.s}
          artist={challenge.map.a}
          mapper={challenge.map.u}
          type={challenge.level}
          goal={goal}
        />
      );
    };

    return (
      <div className="container pack mt-5">
        <div className="row align-items-center mb-4">
          <div className="col-6">
            <h1 className="text-white m-0">Today&apos;s Challenges</h1>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <a
                  className="btn btn-dark btn-lg btn-block w-100"
                  href="https://beatquest.com/api/playlist"
                  download="beatquest.bplist"
                >
                  <i className="fas fa-download"></i>&nbsp; Download Playlist
                </a>
              </div>
              <div className="col-6">
                <a
                  className="btn btn-dark btn-lg btn-block w-100"
                  href="bsplaylist://playlist/https://beatquest.com/api/playlist"
                >
                  <i className="fas fa-cloud-download-alt"></i>&nbsp; One-Click Install
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <Category icon="bullseye-arrow" name="Acc" description="Achieve the specified accuracy to earn points." />
          {this.state.data.pool.filter((challenge) => challenge["type"] == "acc").map(quest)}
        </div>
        <div className="row align-items-center">
          <Category icon="swords" name="Cuts" description="Miss at most the specified amount to earn points." />
          {this.state.data.pool.filter((challenge) => challenge["type"] == "cuts").map(quest)}
        </div>
        <div className="row align-items-center">
          <Category icon="cubes" name="Combo" description="Score at least the specified max combo to earn points." />
          {this.state.data.pool.filter((challenge) => challenge["type"] == "combo").map(quest)}
        </div>
        <h6 className="m-0 text-white text-center">
          <Badge type="streak" size="inline" text="100 PTS" /> bonus for multi-day streak &nbsp;&middot;&nbsp; Next
          batch in <Timer />
        </h6>
      </div>
    );
  }
}

export default Pack;
