import React from "react";

import Pack from "../components/Pack";
// import Jumbotron from "../components/Jumbotron";
// <Jumbotron />

class Home extends React.Component {
  render() {
    return (
      <>
        <Pack />
      </>
    );
  }
}

export default Home;
