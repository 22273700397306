import React from "react";

import seal from "../images/beatquest-seal.svg";

class Jumbotron extends React.Component {
  render() {
    return (
      <div className="background-hero-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-3">
              <img src={seal} alt="Beat Quest" className="w-100" />
            </div>
            <div className="col-9"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Jumbotron;
