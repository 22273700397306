import React from "react";

import Jumbotron from "../components/Jumbotron";
import Timer from "../components/Timer";

class FAQs extends React.Component {
  render() {
    return (
      <>
        <Jumbotron />
        <div className="container my-5 text-white">
          <h2>How often does the challenge pool reset?</h2>
          <p>
            The set of challenges automatically resets every day at 4:00 AM CST, which is equivalent to 2:00 AM PST,
            5:00 AM EST, or 10:00 AM GMT. As soon as the challenge pool resets, the previous set of challenges will
            become invalid, and can no longer be used to score points. The next set of challenges will be released in{" "}
            <Timer />.
          </p>
        </div>
      </>
    );
  }
}

export default FAQs;
