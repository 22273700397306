import React from "react";
import { Link } from "react-router-dom";

import seal from "../images/beatquest-seal.svg";

class NotFound extends React.Component {
  render() {
    return (
      <>
        <div className="container text-center py-5 my-5 text-white">
          <img src={seal} alt="Beat Quest" width="300" />
          <h1 className="mt-5">Page not found</h1>
          <p>The page you requested isn&apos;t available.</p>
          <p>
            <Link to="/">Return to home</Link>
          </p>
        </div>
      </>
    );
  }
}

export default NotFound;
