import React from "react";
import PropTypes from "prop-types";

import bronze from "../images/icon-bronze.svg";
import silver from "../images/icon-silver.svg";
import gold from "../images/icon-gold.svg";
import platinum from "../images/icon-platinum.svg";
import diamond from "../images/icon-diamond.svg";
import streak from "../images/icon-streak.svg";

class Badge extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(["bronze", "silver", "gold", "platinum", "diamond", "streak"]).isRequired,
    text: PropTypes.string.isRequired,
    size: PropTypes.string
  };

  render() {
    const icons = {
      bronze,
      silver,
      gold,
      platinum,
      diamond,
      streak
    };

    return (
      <span className={"badge badge-" + this.props.type + (this.props.size ? " badge-" + this.props.size : "")}>
        <span className="icon">
          <img alt={this.props.type} src={icons[this.props.type]} />
        </span>
        <span className="text">{this.props.text}</span>
      </span>
    );
  }
}

export default Badge;
